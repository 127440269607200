import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Container } from "reactstrap";
import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import RightSideBar from "../right-sidebar";
import Breadcrumb from "../../Common/breadcrumb";
import UserProvider from '../../../UserProvider';
import Track from '../../../Track';
import { FormattedMessage } from 'react-intl';
import { ToastContainer } from 'react-toastify';

class LayoutV extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title =
      currentage + " | Sosusk";
  }

  render() {
    return (
      <UserProvider>
        <Track>
          <div id="layout-wrapper">
              <ToastContainer
                closeOnClick
                draggable
                autoClose={10000}
              />
              {/* render topbar */}
              <Topbar/>

              {/* render navbar */}
              <Navbar/>
              
              <div className="main-content">
                <div className="page-content">
                  <Container fluid>
                    <Breadcrumb/>
                    {this.props.children}
                    {/* render Footer */}
                    <Footer/>
                  </Container>
                </div>
              </div>
            </div>
            <RightSideBar/>
        </Track>
      </UserProvider>
    );
  }
}

const mapStatetoProps = state => {
  const Layout = state.Layout;
  return {
      isPreloader : Layout.isPreloader
  };
};

export default withRouter(connect(mapStatetoProps, null)(LayoutV));
