import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                    <footer className="footer">
                        <Container fluid>
                            <Row>
                                <Col sm="12">
                                    <FormattedMessage id="COPYRIGHT" values={{ year: new Date().getFullYear() }} />
                                </Col>
                            </Row>
                            <Container>
                                <p className="text-center text-dark pb-0" style={{ fontSize: '.75em' }}>The European Commission support for the production of this publication does not constitute an endorsement of the contents which reflects the views only of the authors, and the commission cannot be held responsi¬ble for any use which may be made of the information contained therein.</p>
                            </Container>
                        </Container>

                    </footer>
            </React.Fragment>
        );
    }
}

export default Footer;