import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Link, withRouter, Redirect } from 'react-router-dom';
import logodark from "../../assets/images/logo-dark.png";
import { log_in, exchange_token, is_logged_in } from '../../api';
import Validator from '../../component/Common/Validator';
import qs from 'query-string';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LanguageSwitcherBasic } from '../../lang';
import {EuLogo} from "./EuLogo";

export default injectIntl(class Pageslogin extends Component {
    state = { };

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({
            messages: {
                email: props.intl.formatMessage({ id: 'VALIDATION.EMAIL' }),
                default: props.intl.formatMessage({ id: 'VALIDATION.REQUIRED' })
            }
        });
    }

    async componentDidMount() {
        var params = qs.parse(this.props.location.search);
        if(params.code) {
            this.setState({ loading: true });
            try {
                await exchange_token(params.code);
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return ;
        }

        this.setState({ loading: true });
        try {
            await log_in(this.state.email, this.state.password);
            this.forceUpdate();
        } catch(e) {
            this.validator.hideMessages();

            Swal.fire(
                this.props.intl.formatMessage({ id: 'GENERAL.ERROR' }),
                this.props.intl.formatMessage({ id: 'AUTH.INVALID_CREDIENTALS' }),
                'error'
            );
        } finally {
            this.setState({ loading: false, password: null });
        }
    }

    render() {
        const { intl } = this.props;
        
        if(is_logged_in()) {
            return <Redirect to="/" />
        }

        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="\" className="logo logo-admin"><img src={logodark} height="30" alt="logo" /></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-1 text-center"><FormattedMessage id="AUTH.WELCOME" /></h4>
                                            <p className="text-muted text-center"><FormattedMessage id="AUTH.SIGNIN_CONTINUE" /></p>

                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                <FormGroup>
                                                    <Label><FormattedMessage id="AUTH.EMAIL" /></Label>
                                                    <Validator name="email" type="required|email" controller={this}>
                                                        <Input
                                                            type="text"
                                                            value={this.state.email || ""}
                                                            onChange={a => this.setState({ email: a.currentTarget.value })}
                                                            placeholder={intl.formatMessage({ id: 'AUTH.EMAIL' })}
                                                            disabled={this.state.loading}
                                                        />
                                                    </Validator>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label><FormattedMessage id="AUTH.PASSWORD" /></Label>
                                                    <Validator name="password" type="required" controller={this}>
                                                        <Input
                                                            type="password"
                                                            value={this.state.password || ""}
                                                            onChange={a => this.setState({ password: a.currentTarget.value })}
                                                            placeholder={intl.formatMessage({ id: 'AUTH.PASSWORD' })}
                                                            disabled={this.state.loading}
                                                            autoComplete="current-password"
                                                        />
                                                    </Validator>
                                                </FormGroup>
                                                <Row form className="mb-3">
                                                    <Col sm="6" className="align-self-center">
                                                        <CustomInput disabled={this.state.loading} className="text-left font-14" type="checkbox" id="rememberme" label={intl.formatMessage({ id: 'AUTH.REMEMBER_ME' })} />
                                                    </Col>
                                                    <Col sm="6" className="text-right">
                                                        <Button color="primary px-3" disabled={this.state.loading}>
                                                            { (this.state.loading && <i className="mdi mdi-loading mdi-spin mr-1" />) || <i className="mdi mdi-login mr-1" />}
                                                            <FormattedMessage id="AUTH.COMPLETE_LOGIN" />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <FormGroup row>
                                                    <Col md="12" className="mt-4">
                                                        <FormattedMessage
                                                            id="AUTH.REGISTER"
                                                            values={{
                                                                a: text => <Link className="btn btn-primary btn-sm ml-2" to="/register">{text}</Link>
                                                            }}
                                                        />
                                                        <br />
                                                        <Link className="text-muted" to="/forget-password">
                                                            <i className="mdi mdi-lock" />{' '}
                                                            <FormattedMessage id="AUTH.FORGET_PASSWORD" />
                                                        </Link>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <LanguageSwitcherBasic />

                            </Col>
                        </Row>
                    </Container>
                    <Container>
                    <EuLogo />
                    <div className="mt-5 text-center">
                        <p>© 2023 Sosusk</p>
                    </div>
                    </Container>

                </div>
            </React.Fragment>
        );
    }
});
