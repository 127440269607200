
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink } from "react-router-dom";
import { tokenized } from '../api';

class CategoryMenu extends React.Component {
    state = { open: false };

    async componentDidMount() {
        var category = await tokenized.get('/category/' + this.props.id);

        this.setState({ category: category.data });
    }

    handleClick(id, e) {
        e.preventDefault();
        this.setState({ open: this.state.open === id ? null : id });
    }

    render() {
        if(!this.state.category)
            return null;

        return <>
            <li className="menu-title">{ this.state.category.name }</li>
            <li><NavLink to="/intro" className="faq-menu" activeClassName={"mm-active"}><FormattedMessage id="INFO.MENU" /></NavLink></li>
            { 
                this.state.category.childerens
                    .map(a => <li key={a.id} className={ a.type === 1 && this.state.open === a.id ? 'mm-active' : '' }>
                        <Link to={a.type === 2 ? `/category/${a.id}` : "/#"} onClick={a.type !== 1 && this.handleClick.bind(this, a.id)} className="has-arrow waves-effect">
                            <i className="mdi mdi-share-variant"></i>
                            <span>{ a.name }</span>
                        </Link>
                        { a.type !== 2 && this.state.open === a.id && <CategorySubMenu id={a.id} />}
                    </li>)
            }
        </>;
    }
}

class CategorySubMenu extends CategoryMenu {
    render() {
        if(!this.state.category)
            return null;

        if(this.state.category.childerens.length === 0) {
            return <ul className="sub-menu" aria-expanded="true">
                <li><a>No content</a></li>
            </ul>;
        }

        return <ul className="sub-menu" aria-expanded="true">
            { 
            this.state.category.childerens
                .map(a => <li key={a.id} className={ a.type === 1 && this.state.open === a.id ? 'mm-active' : '' }>
                    <NavLink activeClassName={a.type === 2 ? "mm-active" : ""} to={a.type === 2 ? `/category/${a.id}` : "/#"} onClick={a.type === 1 && this.handleClick.bind(this, a.id)}  className={ a.type === 1 ? "has-arrow" : "" }>{ a.name }</NavLink>
                    { a.type === 1 && this.state.open === a.id && <CategorySubMenu id={a.id} />}
                </li>)
            }
        </ul>;
    }
}
export default CategoryMenu;
